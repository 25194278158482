.card {
	position: relative;
	padding-bottom: 25px;
	margin-bottom: 25px;
}

.card--theme1 {
	.columns {
		position: relative;
		display: flex;		

		.card {
			flex:1 1 100%;
			&:after {
				position: absolute;
				bottom: 0;
				width: 100%;
				height: 1px;
				content: '';
				background-color: rgba(c(blue), .5);
			}
			h2, h3 {
				margin-top: 10px;
				color: c(grey, medium);
			}
			p {
	
			}
			a {		
				display: block;
				margin-top: 15px;
				text-transform: uppercase;
				font-weight: 600;
			}			
		}

	}

}

@include bp (sm) {
	.card--theme1 {
		.columns {
			.card {
				padding-bottom: 50px;
				p {
					margin-top: 10px;
				}
				a {
					position: absolute;
					bottom:20px;
					left:0;
				}
			}
		}
	}
}
