// @font-face {
//   font-family: 'fontello';
//   src: url('../font/fontello.eot?58112239');
//   src: url('../font/fontello.eot?58112239#iefix') format('embedded-opentype'),
//        url('../font/fontello.woff2?58112239') format('woff2'),
//        url('../font/fontello.woff?58112239') format('woff'),
//        url('../font/fontello.ttf?58112239') format('truetype'),
//        url('../font/fontello.svg?58112239#fontello') format('svg');
//   font-weight: normal;
//   font-style: normal;
// }
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?58112239#fontello') format('svg');
  }
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

$icon-search:'\e800'; /* '' */
$icon-mail:'\e801'; /* '' */
$icon-star:'\e802'; /* '' */
$icon-star-empty:'\e803'; /* '' */
$icon-ok:'\e804'; /* '' */
$icon-cancel:'\e805'; /* '' */
$icon-attention:'\e806'; /* '' */
$icon-phone:'\e807'; /* '' */
$icon-wrench:'\e808'; /* '' */
$icon-play:'\e809'; /* '' */
$icon-stop:'\e80a'; /* '' */
$icon-down-dir:'\e80b'; /* '' */
$icon-up-dir:'\e80c'; /* '' */
$icon-left-dir:'\e80d'; /* '' */
$icon-right-dir:'\e80e'; /* '' */
$icon-pencil:'\e80f'; /* '' */
$icon-cog:'\e810'; /* '' */
$icon-cog-alt:'\e811'; /* '' */
$icon-camera:'\e812'; /* '' */
$icon-down-1:'\e813'; /* '' */
$icon-left-1:'\e814'; /* '' */
$icon-right-1:'\e815'; /* '' */
$icon-up-1:'\e816'; /* '' */
$icon-cog-1:'\e817'; /* '' */
$icon-location:'\e818'; /* '' */
$icon-phone-1:'\e819'; /* '' */
$icon-check:'\e81a'; /* '' */
$icon-cancel-1:'\e81b'; /* '' */
$icon-plus:'\e81c'; /* '' */
$icon-gauge:'\e81d'; /* '' */
$icon-basket:'\e81e'; /* '' */
$icon-calendar:'\e81f'; /* '' */
$icon-info-circled:'\e820'; /* '' */
$icon-picture:'\e821'; /* '' */
$icon-videocam:'\e822'; /* '' */
$icon-help-circled:'\e823'; /* '' */
$icon-tags:'\e824'; /* '' */
$icon-asterisk:'\e825'; /* '' */
$icon-credit-card:'\e826'; /* '' */
$icon-lock:'\e827'; /* '' */
$icon-lock-open:'\e828'; /* '' */
$icon-home:'\e829'; /* '' */
$icon-heart:'\e82a'; /* '' */
$icon-heart-empty:'\e82b'; /* '' */
$icon-bookmark:'\e82c'; /* '' */
$icon-flag:'\e82d'; /* '' */
$icon-trash-empty:'\e82e'; /* '' */
$icon-user:'\f061'; /* '' */
$icon-check-empty:'\f096'; /* '' */
$icon-bookmark-empty:'\f097'; /* '' */
$icon-twitter:'\f099'; /* '' */
$icon-facebook:'\f09a'; /* '' */
$icon-certificate:'\f0a3'; /* '' */
$icon-gplus:'\f0d5'; /* '' */
$icon-mail-alt:'\f0e0'; /* '' */
$icon-linkedin:'\f0e1'; /* '' */
$icon-comment-empty:'\f0e5'; /* '' */
$icon-plus-squared:'\f0fe'; /* '' */
$icon-angle-left:'\f104'; /* '' */
$icon-angle-right:'\f105'; /* '' */
$icon-angle-up:'\f106'; /* '' */
$icon-angle-down:'\f107'; /* '' */
$icon-circle-empty:'\f10c'; /* '' */
$icon-flag-empty:'\f11d'; /* '' */
$icon-flag-checkered:'\f11e'; /* '' */
$icon-direction:'\f124'; /* '' */
$icon-help:'\f128'; /* '' */
$icon-info:'\f129'; /* '' */
$icon-lock-open-alt:'\f13e'; /* '' */
$icon-ellipsis:'\f141'; /* '' */
$icon-ellipsis-vert:'\f142'; /* '' */
$icon-minus-squared:'\f146'; /* '' */
$icon-minus-squared-alt:'\f147'; /* '' */
$icon-doc-inv:'\f15b'; /* '' */
$icon-youtube:'\f167'; /* '' */
$icon-down:'\f175'; /* '' */
$icon-up:'\f176'; /* '' */
$icon-left:'\f177'; /* '' */
$icon-right:'\f178'; /* '' */
$icon-male:'\f183'; /* '' */
$icon-dot-circled:'\f192'; /* '' */
$icon-plus-squared-alt:'\f196'; /* '' */
$icon-google:'\f1a0'; /* '' */
$icon-file-pdf:'\f1c1'; /* '' */
$icon-file-image:'\f1c5'; /* '' */
$icon-circle-thin:'\f1db'; /* '' */
$icon-cc-visa:'\f1f0'; /* '' */
$icon-cc-mastercard:'\f1f1'; /* '' */
$icon-cc-paypal:'\f1f4'; /* '' */
$icon-trash:'\f1f8'; /* '' */
$icon-toggle-off:'\f204'; /* '' */
$icon-toggle-on:'\f205'; /* '' */
$icon-cart-plus:'\f217'; /* '' */
$icon-cart-arrow-down:'\f218'; /* '' */
$icon-facebook-official:'\f230'; /* '' */
$icon-pinterest:'\f231'; /* '' */
$icon-whatsapp:'\f232'; /* '' */
$icon-credit-card-alt:'\f283'; /* '' */
$icon-envelope-open:'\f2b6'; /* '' */
$icon-envelope-open-o:'\f2b7'; /* '' */