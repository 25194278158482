// Custom Functions
@import 'functions/em-calc';
@import 'functions/rem-calc';
@import 'functions/grid--flex';
@import 'functions/strip-units';
@import 'functions/bp';
@import 'functions/helpers';
// CSS3
//@import 'css3/prefix'; 
//@import 'css3/transform';
//@import 'css3/transition';
@import 'css3/font-face';
//@import 'css3/media-query'; 
//@import 'css3/media-query--hi-dpi'; 
//@import 'css3/feature-detection'; 

//Addons
@import 'helpers/mixins';
//@import 'helpers/animate-simple';
//@import 'helpers/animate';