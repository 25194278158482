.testimonial {
	margin-top: 40px;
	padding: 40px 0;	
	blockquote {
		padding: 0;
		margin: 0;
		text-align: left;
		p {
			margin-top: 0;
			position: relative;
			display: block;
			font-family: $display-font;
			font-size: 17px;
			padding: 0 0 0 35px;
			text-align: left;
			color: c(grey, dark);
			&:before , &:after {
				display: inline-block;			
				font-size: 50px;
				color: rgba(#000, .25);
			}
			&:before {	
				position: absolute;		
				top:-20px;
				left:0;
				content:'“';			
			}

			&:after {
				position: relative;			
				line-height: 0;
				content:'”';
				top:20px;			
				bottom: 0;	
				margin-left: 4px;	
			}
		}
		cite {
			display: block;
			margin:10px 35px 0;
		}
	}
}

@include bp (sm) {
	.testimonial {
		blockquote {
			padding: 0;
			margin: 0;
			p {
				font-size: 22px;
			}
		}
	}
}
@include bp (md) {
	.testimonial {		
		max-width:66.6666%;
		margin-left: auto;
		margin-right: auto;
		blockquote {
			padding: 0;
			margin: 0;
			p {
				font-size: 22px;
			}
		}
	}
}
@include bp (xxs, max) {
	.testimonial {	
		padding-bottom: 50px;
		[class*="small-"] {
			flex:100%;
			max-width: 100%;
		}
		blockquote {
			margin-top: 15px;	
			p {				
				font-size: 14px;
				line-height: 1.9;
			}		
			cite {
				font-size: 12px;
			}
		}
	}
}