
/*
	Function to access colour palette from $colours var
	Usage:
	@include bp(w(sm)) {
		
	}
 */
@function w($width) {
	@return map-get($breakpoints, $width);
}


/*
	Function to access colour palette from $colours var
	Usage:
	.class {
		color: c(red, dark);
	}
 */
@function c($palette, $tone: 'base') {
	@return map-get(map-get($colours, $palette), $tone);
}


/*
	Function to access z-index from $z-index var
	Usage:
	.class {
		z-index: z(modal);
	}
 */
@function z($target) {
	@return map-get($z-index, $target);
}


/*
	Helper function - used to return int value
 */
@function parseInt($n) {
	@return $n / ($n * 0 + 1);
}