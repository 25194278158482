// Font imports

// Open sans
//@import url('https://fonts.googleapis.com/css?family=Lato:300,400,400i,800');
//@import url('https://fonts.googleapis.com/css?family=Lato:300,400,400i,800');
@import url('https://fonts.googleapis.com/css?family=Work+Sans:300,400,400i,600,800');
@import url('https://fonts.googleapis.com/css?family=Bitter:400,700');
//@import 'https://fonts.googleapis.com/css?family=Open+Sans:300,400,400i,600,700,700i,800';

// Roboto Slab
//@import url('https://fonts.googleapis.com/css?family=Roboto+Slab');

// fontawesome
// @include font-face('FontAwsome', '../fonts/fontawsome/fontawesome-webfont');


// fontello icons
@include font-face('Fontello', '../fonts/fontello/font/fontello');
