
.content {
	h1 {
		margin-top: 15px;
		margin-bottom: 15px;
	}
	.list-stacked {
		list-style: none;
		> li {
			padding-left: 0;
			margin-left: 0;
		}
	}
}



/* Typography styles */
.large-msg {
	max-width: 800px;
	margin: 0 auto 50px;
	padding: 30px 0 38px;
	//background-color: c(grey, light);
	h2 {
		display: block;		
		text-align: center;
		font-size: 20px;
	}
}




@include bp (xxs) {
	.large-msg {
		padding-left: 20px;
		padding-right: 20px;		
	}
}


@include bp (sm) {
	.large-msg {
		//padding-top: 50px;
		h2 {			
			font-size: 24px;
		}
	}
}



blockquote {
	margin-top: 30px;
	margin-bottom: 30px;
	text-align: left;
	padding: 30px 15px;	
	p {
		margin-top: 0;
		padding: 0;
		font-family: $display-font;
		font-size: 24px;
		font-weight: 600;
	}
}
@include bp(sm) {
	blockquote {
		margin: 10px 20% 40px;
		p {
			font-family: $display-font;
			font-size: 24px;
		}
	}
}

// *::selection { background-color: c(red); }


