.btn--cta {
    text-transform: uppercase;
    font-size: 11.5px;
    background-color: c(blue);
    color: c(white);
    border: 1px solid transparent;
    outline:none;
    border-radius: 2px;
    padding: 10px;
}

.btn--outline {
	background-color: transparent;
    border-color: rgba( c(white), .75);
    color: c(white);
}


.btn--outline-blue {
	background-color: transparent;
    border-color: c(blue);
    color: c(blue);
}


%link {
    position: relative;
    display: inline-block;
    padding-left: 24px;
    min-height: 20px;
    text-transform: uppercase;
    font-weight: 400;
    color: c(grey,dark);
    &:before,
    &:after {
        display: block;
        position: absolute;
        left:0;
        top:50%;       
        transform:translateY(-50%);
        width: 30px;
        height: 20px;
        text-align: left;
        font-family: 'Fontello';
        font-size: 12px;
        line-height: 20px;
        color: c(grey,dark);
        font-weight: normal;
        text-indent: 0;
    }
    &:before {
        left:0;
    }
    &:after {
        right:0;
    }
    &:hover, &:focus {
        color: c(blue);
        &:before {
            color: c(blue);
        }
    }
}

.link--location {
    @extend %link;
    &:before {
        content:$icon-location;
    }
}

.link--phone {
    @extend %link;
    &:before {
        content:$icon-phone;
    }
}

.link--email {
    @extend %link;
    text-transform: lowercase;
    &:before {
        content:$icon-mail;
    }
}
.link--cta {
    @extend %link;
    padding-left: 0;
    padding-right: 30px;
}

%icon {
    position: relative;
    display: block;
    padding-left: 24px;
    width: 40px;
    height: 40px;
    text-indent: -9999px;
    text-transform: uppercase;
    font-weight: 400;
    border:1px solid rgba(#fff, .5);
    border-radius: 50%;
    &:after {
        display: block;
        position: absolute;
        top:50%;       
        transform:translateY(-50%);
        width: 40px;
        height: 30px;
        text-align: center;
        font-family: 'Fontello';
        font-size: 12px;
        line-height: 20px;
        color: #FFF;
        font-weight: normal;
        text-indent: 0;
    }
}

.icon--facebook {
    @extend %icon;
    &:after {
        content:$icon-facebook;
    }
}

