/* Breakpoint mixin */
@mixin bp($width, $type: min) {
    // Width using set key
    @if map_has_key($site-breakpoints, $width) {
        $width: map_get($site-breakpoints, $width);
        @if $type == max {
            $width: $width - 1px;
        }
        @media only screen and (#{$type}-width: $width) {
            @content;
        }
    }
    // Width using number
    @else
    {
        @if $type == max {
            $width: $width - 1px;
        }
        @media only screen and (#{$type}-width: $width) {
            @content;
        }
    }
}
/* Breakpoint between two sizes */
@mixin bp-minmax($min, $max) {
    // Width using set key
    @if map_has_key($site-breakpoints, $min) and map_has_key($site-breakpoints, $max) {
        $min: map_get($site-breakpoints, $min);
        $max: map_get($site-breakpoints, $max);
        @media only screen and (min-width: $min) and (max-width: ($max - 1px)) {
            @content;
        }
    }
}

/* Breakpoint mixin */
@mixin bp-height($height, $type: min) {
    // Width using set key
    @if map_has_key($site-breakpoints, $height) {
        $height: map_get($site-breakpoints, $height);
        @if $type == max {
            $height: $height - 1px;
        }
        @media only screen and (#{$type}-height: $height) {
            @content;
        }
    }
    // height using number
    @else
    {
        @if $type == max {
            $height: $height - 1px;
        }
        @media only screen and (#{$type}-height: $height) {
            @content;
        }
    }
}
/* Breakpoint between two sizes */
@mixin bp-height-minmax($min, $max) {
    // height using set key
    @if map_has_key($site-breakpoints, $min) and map_has_key($site-breakpoints, $max) {
        $min: map_get($site-breakpoints, $min);
        $max: map_get($site-breakpoints, $max);
        @media only screen and (min-height: $min) and (max-height: ($max - 1px)) {
            @content;
        }
    }
}





// Create Extra Foundation Columns
@mixin foundation-columns($className) {
    @for $i from 1 through 12 {
        $width: percentage($i / 12);
        #{$className}#{$i)} {
           flex: 0 0 $width;
           max-width: $width;
        }
    }
}



