.owl-carousel {
	background-color:c(grey,dark);
	//background: linear-gradient(135deg, rgba(darken(c(blue), 30%),.15) 0%,rgba(c(grey,dark),.2) 100%);
	position: relative;

	&.is-loaded .slide {
		opacity: 1;
	}

	.slide {
		position: relative;
		min-height: 94px;
		opacity: 0;
		transition:.5s opacity 1s;
		.slide__content {
			padding: 25px 0 40px;
			background-color: c(grey,dark);
		    transform-style: preserve-3d;
		    backface-visibility: hidden;		   
			h2, p {
				color: c(white);
			}
			h2 {
				margin-top: 0;
				margin-bottom: 10px;				
				font-weight: 700;
				font-size: 30px;
				letter-spacing: 1px;
			}
			p {
				font-size: 16px;
				margin-bottom: 25px;
				font-weight: 300;
			}
		
			.btn--cta {
				//padding: 4px 8px;
				&:hover, &:focus {
			    	border-color: lighten(c(blue), 10%);
			   	 	color:  lighten(c(blue), 10%);
				}
			}
			@include bp(xs, max) {
				text-align: center;
			}
		}
		.slide__inner {
			margin: 0 $site-gutter;	
		    @include bp(xxs) {
		        margin: 0 $site-gutter-xxs;
		    }
		    @include bp(xs) {
		        margin: 0 $site-gutter-xs;
		    }
		    @include bp(sm) {
		        margin: 0 $site-gutter-sm;
		    }
		}

	}
	.owl-nav {
		position: absolute;
		left:0;
		top:0;
		width: 100%;
		height: 50px;
		.owl-prev,
		.owl-next {
			display: block;
			position: absolute;
			width: 30px;
			height: 50px;
			background:rgba(#333, .5);
			//background:red;
			text-indent: -9999px;
			&:after {
				display: block;
				position: absolute;
				top:50%;
				left:0;
				transform:translateY(-50%);
				width: 30px;
				height: 25px;
				text-align: center;
				font-family: 'Fontello';
				font-size: 24px;
				color: #FFF;
				font-weight: normal;
				text-indent: 0;
				opacity: .5;
			}
		}
		.owl-prev {
			left: 0;
			&:after {
				content:$icon-angle-left;
			}
		}
		.owl-next {
			right: 0;
			&:after {
				content:$icon-angle-right;
			}
		}
	}
	.owl-dots {
		display: none;
		position: absolute;
		bottom:15px;
		left:50%;
		transform:translateX(-50%);
		.owl-dot {
			margin: 0 5px;
			display: inline-block;
			width: 12px;
			height: 12px;
			background-color: #fff;
			border-radius: 50%;
			opacity: .5;
			transition:.3s;
			&.active {
				opacity: 1;
			}
		}
	}

}
@include bp(xs) {
	.owl-carousel {
		.owl-dots {
			display: block;
		}
	}
}
@include bp(sm) {
	.owl-carousel {
		margin-top: -36px;
		.slide {
			.slide__content {
				p {
					min-height: 75px;
				}
			}
		}
	}
}


@include bp(md) {
	.owl-carousel {
		margin-top: -36px;
		min-height: 375px;		
		.slide {
			.slide__content {
				position: absolute;
				top:50%;
				left:0;
				transform:translateY(-50%);
				width: 33%;
				margin-left: $site-gutter-md;
				//background-color: rgba( c(grey,dark), .8);
				background: linear-gradient(135deg, rgba(darken(c(blue), 20%),.9) 0%,rgba(c(grey,dark),.75) 100%);
				transition:.5s all $cb .5s;				
				p {
					font-size: 13px;
					min-height: 50px;
				}
			}
		}
		.owl-nav {	
			top:50%;
			transform:translateY(-50%);			
		}

	}

	.owl-item {	
		.slide {
			z-index: 1;
			overflow: hidden;
			.slide__bg {
				position: absolute;
				z-index: 1;
				top:0;
				left:0;
				width: 100%;
				height: 100%;
				background-color: rgba(#000, .5);
				background-position: 0 0;
				background-size: cover;
				transition:6s all $cb;
				transform:translateX(100px) scale(1.25);
			}

			.slide__content {
				z-index: 2;
				left:200px;
				opacity: 0;
			}
			.slide__inner {
				margin-left: 20px;
				margin-right: 20px;
			}
		}
		
		&.is-loaded,
		&.active {
			.slide {
				.slide__content {
					left:$site-gutter;
					opacity: 1;
				}
				.slide__bg {
					transform:translateX(0) scale(1.005);
				}
			}
		}
	}
		
	
	.owl-nav {
		top:50%;		
		transform:translateY(-50%);
	}
}

@include bp(md, max) {	
	.owl-carousel {
		background: linear-gradient(-135deg, rgba(darken(c(blue), 20%),1) 0%,rgba(c(grey,dark),1) 100%);
		.owl-item {
			.slide {
				background-color: transparent;
				.slide__content {
					background-color: transparent;
				}
			}
		}
		.owl-nav {			
			.owl-prev,
			.owl-next {
				width: 20px;
				height: 30px;
				background-color: rgba(darken(c(blue), 20%),.5);
				&:after {
					width: 20px;
					height: 14px;
					font-size: 14px;
				}
			}
		}
	}
}
@include bp(xxs, max) {	
	.owl-carousel {
		opacity: 1;
		.slide {
			.slide__content {
				min-height: 240px;
				padding-top: 15px;
				h2 {					
					font-size: 22px;
				}
				p {
					margin-top: 0;
					font-size: 12px;
					line-height: 1.8;
				}
			}
		}		

	}
}

// reduce height for small wide screens
@media only screen and (min-width:map_get($site-breakpoints, 'lg')) and (max-height: 759px) {	
	.owl-carousel {
		.slide {
			height: 400px;
		}
	}
}


// NO JS
.no-js {
	.owl-carousel {		
	
		.slide {
			display: none;
			&:first-child {
				display: block;
				opacity: 1;
			}		
			img {
				display: block;
				width: 100%;				
			}	
			> img {
				display: none;
			}
			&__content {
				transition:none;
			}
		}
	}

}