
.primary_nav {
    position: absolute;
    z-index: 20;
    left: 100%;
    width: 220px;
    background-color:  c(grey, dark);
    transform: translateX(0px);
    transition: .3s all $cb;
    transform-style: preserve-3d;
    backface-visibility: hidden;
	@include bp(sm, max) {
		overflow-y:auto;
		overflow-x:hidden;
	}
    .menu-primary_nav-container {
    	position: relative;
        height: 100%;
		height: calc(100vh - 59px);
        background-color: c(grey, dark);
        background: linear-gradient(0deg, rgba(darken(c(blue), 20%),1) 0%,rgba(c(grey,dark),1) 100%);
		&:before {
			position: absolute;
			content: '';
			left:0;
			top:0;
			width: 100%;
			height: 100%;         
			background: transparent url('../images/mxs-masked-white.png') center bottom no-repeat;
			background-size: cover;
			background-position: calc(50% - 100px) bottom;
			opacity: .02;
		} 
        > .menu {
        	//display: block;
        	//background-color: lighten(c(grey, dark), 10%);
            > li {
				position: relative;
            	> .nav-link {
	                position: relative;
	                display: block;
	                border-bottom: 1px solid rgba(#fff, .15);
	                > a {
	                    position: relative;
	                    display: block;
	                    width: 100%;
	                    padding: 12px 50px 12px 10px;
	                    color: #fff;
	                    text-transform: uppercase;
	                    font-size: 11px;
	                    font-weight: 300;
	                    letter-spacing: 1px;
	                    
	                    &:hover,
	                    &:focus {
	                    	text-decoration: none;
	                    	color: c(blue, medium);
	                    }
	                }
	                > .toggle-sub-menu {
	                    position: absolute;
	                    top:0;
	                    right:0;
	                    margin: 0;
	                    display: block;
	                    appearance: none;
	                    border: none;
	                    width: 50px;
	                    height: 100%;
	                    background-color: transparent;
	                    border: none;
	                    outline: 0 none;
	                    cursor: pointer;
	                    border-left: 1px solid rgba(#fff, .15);
	                    > span {
	                        display: block;
	                        text-indent: -9999px;
	                    }
	                    &:after {
	                        position: absolute;
	                        top: 50%;
	                        left: 0;
	                        width: 50px;
	                        text-align: center;
	                        line-height: 100%;
	                        content: $icon-angle-down;
	                        display: block;
	                        font-family: 'Fontello';
	                        font-size: 18px;
	                        font-weight: normal;
	                        color: #fff;
	                        transition: .3s all $cb;
	                        transform: translateY(-50%) rotate(0deg);
	                        transform-origin: center center;
						    transform-style: preserve-3d;
						    backface-visibility: hidden;
	                    }
	                    &:hover, &:focus {
							&:after {
								color: c(blue, medium);
							}
	                    }
	                }
	            }
                &.has-children {
                    > .nav-link > a {
                    	position: relative;
                    	padding-right: 35px;
                    	&:before {
                    		display: none;
	                        position: absolute;
	                        top: 50%;
	                        left:auto;
	                        right: 0;
	                        width: 50px;
	                        height: 12px;
	                        text-align: center;
	                        line-height: 100%;
	                        content: $icon-angle-down;	                        
	                        font-family: 'Fontello';
	                        font-size: 14px;
	                        font-weight: normal;
	                        color: #fff;
	                        transition: .3s all $cb;
	                        transform: translateY(-50%) rotate(0deg);
	                        transform-origin: center center;
						    transform-style: preserve-3d;
						    backface-visibility: hidden;
	                    }
                    }   
                	&.is-open {
	                    > .nav-link > .toggle-sub-menu {                  
	                        &:after {
	                            transform: translateY(-50%) rotate(180deg);
	                        }                        
	                    }             
		            }

		        }
                &.is-open {
                    .sub-menu {
                        display: block;
                        height: auto;
                        max-height: 1000px;
                        transition: .3s height $cb;
                    }
                }	
                &.is-active {
            		> .nav-link > a:after {	
            			position: absolute;	                		
						content: " ";
						height: 100%;
						width: 1px;	
						top:0;					
						left: 0;								
						pointer-events: none;	
						background-color: c(blue);							
					}
                }            
            }
        }
        .sub-menu {
        	overflow: hidden;
        	//height: 0;
            max-height: 0px;
            transition: .3s all $cb;
            li {
				&.is-active {
					> .nav-link > a:after {	
            			position: absolute;	                		
						content: " ";
						height: 100%;
						width: 1px;	
						top:0;					
						left: 0;								
						pointer-events: none;	
						background-color: c(blue);							
					}  
				}
	            > .nav-link > a {
	                position: relative;
	                display: block;
	                width: 100%;
	                padding: 12px 12px 12px 20px;
	                color: #fff;
	                text-transform: uppercase;
	                font-size: 10px;
	                font-weight: 300;
	                letter-spacing: 1px;
	                background-color: rgba(#fff, .05);
	                border-bottom: 1px solid rgba(#fff, .15);   
	                &:hover,
	                &:focus {
	                	text-decoration: none;
	                	color: c(blue, medium);
	                }    
        		}   
                
	        }
	    }
    }    
}


// Desktop navigation
@include bp(sm) {
	.nav-exit-overlay {
		display: none;
	}
    .primary_nav {
    	width: 100%; 
    	left: 0;
    	transform: translateX(0px);  
		background:transparent;
	    .menu-primary_nav-container {  			
	        height: auto;
	        background: transparent;
	        background:rgba(c(grey, dark), .6666);	
	        &:before {
	    		display: none;
	    	} 			
	        > .menu {
	        	display: inline-block;
	        	display: flex;
	        	flex-flow:row;
	        	justify-content: center;
	        	width: auto;	  
		    	position: relative;
		    	left:50%;
		    	transform:translateX(-50%);	  
				background:c(grey, dark);
				//background-color: darken(c(blue), 20%);
	            > li {
	            	flex:0 0 auto;
	            	position: relative;	  
	            	z-index: 200;          	
	            	display: inline-block;
	            	> .nav-link {
	            		border-bottom: none;
	            		> a {
							background: transparent;
							font-size: 12px;
							font-weight: 300;
							padding-top:8px;
							padding-bottom: 8px;
							&:after {
	            				content: " ";
								height: 1px;
								width: 20px;
								position: absolute;
								bottom:2px;
								left: 100%;	
								opacity: 0;							
								pointer-events: none;	
								background-color: #fff;
								transition: .05s all $cb;
	            			}
	            			&:hover, &:focus {
	            				background-color: rgba(#000, .5);
	            				&:after {
	            					transition: .3s all $cb;
	            					left:10px;
	            					opacity: 1;
	            				}
	            			}
	            		}
	            		> .toggle-sub-menu {
	            			display: none; 
							border-left:none;
							&:after {
								width: 28px;
							}
	            		}

	            	}

	                &.has-children {
	                	.nav-link > a {
		                	&:before {
		                		display: block;
		                	}
		                }
	                	&.is-open {
		                    > .nav-link > .toggle-sub-menu {                  
		                        &:after {
		                            transform: translateY(-50%) rotate(180deg);
		                        }                        
		                    }  
	                    }
	                                 
		            }
		            &:hover {

		            	.sub-menu {
		            		display: block;
	                        height: auto;
	                        max-height: 1000px;	 
	                    }
		            }
					//&:hover {					
	                &.is-open {
	                	//background: red;
	                    .sub-menu {
	                        display: block;
	                        height: auto;
	                        max-height: 1000px;	                        
	                    }
	                }	

	                &.is-active {
	                	background-color: transparent;
	                	.nav-link > a { 
	                		text-align: left;
	                		&:after {		
	                			top:auto; 
	                			bottom: 2px;
	                			left:10px;  
	                			opacity: 1;             		
								height: 1px;
								width: 20px;
								background-color: c(blue);													
							}
	                	}
	                }	                
	            }
	        }
	    

	        .sub-menu {
	        	position: absolute;
	        	top:100%;
	        	width: 180px;
	        	display: block;
	        	background-color: c(grey, dark);
	            > li {
	            	> .nav-link > a {
		            	padding-left: 10px;
		            	border-bottom: none;
		            	text-align-last: left;
		            }
					&.is-active {
	                	background-color: transparent;	                	
	                	.nav-link > a:after { 
	                		top:0;
							left:0;
							width: 3px;
							height: 100%;
	                	}
	                }
	            }
	        }
	    }
	}		

	.nav-active {
	    .primary_nav {
	        transform: translateX(0);
	    }
	    .nav-exit-overlay {
	        display: none;
	    }
	    
	}
}


// Home Page, Landing Page navigation
@include bp(md) {
	.page-template-template-home {
		.primary_nav {
	 		background:transparent;
		    .menu-primary_nav-container {  		
				background:transparent;
				&:before {
					display: none;
				}		
		        > .menu {
					background:rgba(c(grey, dark), .6666);
					background-color: rgba(darken(c(blue), 30%), .6666);
				}
			}
		}
	}
}



/* Overlay that darkens the background */

.nav-exit-overlay {
    position: fixed;
    top:0;
    left:0;
    z-index: 10;
    width: 100%;
    height: 100%;
    //background-color: rgba( c(grey, medium), 0);
    transform: translateX(-100%);
    //transition: 0s -webkit-transform .3s, .3s background-color 0s;
    transition: 0s transform .3s, .3s background-color 0s;
    cursor: pointer;
}
.main {
	filter:none;
}
.nav-active {

	.main {
		filter:blur(1px);
	}
    .primary_nav {
        transform: translateX(-100%);
    }
    .nav-exit-overlay {
        background-color: rgba( c(grey, medium), .8);
        transform: translateX(0%);
       // transition: 0s -webkit-transform .0s, .3s background-color 0s;
        transition: 0s transform .0s, .3s background-color 0s;
    }    
}



