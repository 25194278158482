.contact-us {
	margin-bottom: 0px;
}


@include bp(xxs) {
	.contact-us {
		margin-bottom: 40px;
		address p {
			margin-top: 0;
		}
	}
}

@include bp(md) {
	.contact-us {
		margin-bottom: 40px;
	}
}


@include bp(xxs, max) {
	.contact-us {
		.columns {
			flex:100%;
			max-width: 100%;
			margin-bottom: 20px;			
		}
	}
}