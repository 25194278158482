
// navigation toggle
%nav-toggle-base {
	position: relative;
	display: block;
	width: 30px;
	height: 28px;
	cursor: pointer;
	z-index: 999;
	margin-bottom: 0;
	padding: 0;
	outline: 0 none;

	> span.menu {
		position: relative;
		top:-10px;
		font-size: 9px;
		text-transform: uppercase;
		color: #fff;
		padding-bottom: 20px;
		transition:opacity 0.3s;
		text-indent: -9999px;
	}

    > span.bars {    	
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateX(-50%);
		display: block;
		width: 26px;
		height: 2px;
		background-color: white;
		font-size: 0px;
		user-select: none;
		text-indent: -9999px;
		transition:all 0.3s;
		transform-origin:25% center;
		outline: 0 none;
    	-webkit-transform-style: preserve-3d;
    	-webkit-backface-visibility: hidden;
    	&:before,
    	&:after {
    		position: absolute;
			left: 0;
			width: 100%;
			height: 100%;
			background: white;
			content: '';
			transition:all 0.3s;
    		-webkit-transform-style: preserve-3d;
   		 	-webkit-backface-visibility: hidden;
    	}
	    &:before {
			transform:translateY(-350%);
	    }
	    &:after {
	 		transform:translateY(350%);
	    }
	}
    &:hover,
    &:focus {
        outline: none;
        text-decoration: none;
        background-color: transparent;
    }
}


.nav-toggle {
	@extend %nav-toggle-base;
	appearance:none;
	border:none;
	background-color: transparent;
	width: 56px;
	height: 56px;



}

// OPEN MENU
.nav-active .nav-toggle {
	> span.menu {
		opacity: 0;
	}
	> span.bars {
		margin-top:0;
		background-color: transparent!important;
		transform: rotate(180deg);
		&:before {
			transform: translateY(0) rotate(-45deg);
		}
		&:after {
			transform:translateY(0) rotate(45deg);
		}
	}
}


