.store-label {
    position        : absolute;
    padding         : 3px;
    background-color: red;
    top             : 13px;
    right           : 62px;
    text-transform  : uppercase;
    font-size       : 9px;
    border-radius   : 2px;
    color           : white;
    line-height     : 1;
    z-index         : 1000;
    pointer-events  : none;
    font-weight     : 600;
}

@include bp(sm) {
    .store-label {
        position: absolute;
        top     : -10px;
        left    : 28%;
        right   : auto;
    }
}

.theme--alert {
    background-color: c(blue, medium);
    padding-top     : 10px;
    padding-bottom  : 10px;
    // margin-bottom: 50px;
    text-align      : center;

    h2,
    h3 {
        margin: 0;

        &:before {
            display     : inline-block;
            content     : $icon-certificate;
            font-family : 'Fontello';
            font-size   : 20px;
            line-height : 20px;
            color       : #FFF;
            font-weight : normal;
            text-indent : 0;
            margin-right: 8px;
        }
    }

    a {
        text-decoration: underline;
        color          : c(body);

    }
}

.cart-count {
    visibility: hidden;
}

// Woo overrides
.woocommerce {
    span.onsale {
        background-color: c(blue, medium) !important;
        color           : c(body) !important;
        height          : 3.236em;
        width           : 3.236em;
        line-height     : 3;
        box-shadow      : -3px 3px 0px 0px rgba(0, 0, 0, 0.19);
    }

    #respond input#submit.alt,
    .woocommerce a.button.alt,
    .woocommerce button.button.alt,
    .woocommerce input.button.alt {
        background-color: c(blue);
        font-size       : 13.75px;

        &:hover,
        &:focus {
            background-color: c(blue, dark);
        }
    }

    .product_meta {
        font-size: 12px;
    }


    .product p.price,
    .woocommerce .product span.price {
        color      : c(blue, access);
        font-weight: 600;
    }


    .product p.stock {
        color: c(blue, access);
    }


    .product form.cart .quantity .qty {
        height: 30px;

    }

    h2 {
        font-size: 22px;
    }

    .related.products {
        h2 {
            margin-bottom: 10px;
        }
    }

    ul.products li.product a {
        display : block;
        overflow: hidden;

        img {
            transition: .3s all;
        }

        h2 {
            position: relative;

            &:after {
                position        : absolute;
                top             : 0;
                // transform    : translateY(-50%);
                right           : 0;
                width           : 0px;
                height          : 1px;
                background-color: c(blue);
                content         : '';
                transition      : .3s all ease-out;
            }

        }

        &:hover,
        &:focus {
            img {
                transform: scale(1.05) translateY(-2.5%)
            }

            h2:after {
                width: 100%;
            }
        }
    }

    ul.products li.product .price,
    div.product p.price del,
    div.product span.price del,
    div.product p.price ins,
    div.product p.price div.product span.price ins {
        color: c(blue, access);
    }

    @include bp(sm) {
        ul.products {
            padding-top: 10px;
            border-top : 1px solid c(grey);
        }
    }

    // .woocommerce-error {}

    // .woocommerce-info {}

    .woocommerce-message {
        border-color: c(blue);

        &:before {
            color: c(blue, access);
        }
    }

    .button.button.alt,
    .checkout-button.button.alt {
        background-color: c(blue);

        &:hover,
        &:focus {
            background-color: c(blue, dark);
        }
    }

    .woocommerce-checkout-payment {
        margin-bottom: 40px;
    }

    .select2-container--default .select2-selection--single {
        height       : 35px;
        border       : 1px solid #e0d6d9;
        border-radius: 0;

        .select2-selection__rendered {
            line-height: 2.25;
        }

        .select2-selection__arrow {
            height: 33px;
        }
    }

    .wc_payment_method.payment_method_cheque {
        label {
            padding: 0
        }
    }

    .payment_box.payment_method_cheque {
        >P {
            margin-top: 0;
        }
    }

    #createaccount {
        +span {
            font-size  : 16px;
            font-weight: 600;
        }
    }

    h2.woocommerce-loop-product__title,
    div.product p.price,
    span.price,
    .price {
        color: c(blue, access);
    }

    .woocommerce-MyAccount-navigation {
        ul {
            >li {
                font-weight: 600;

                &.is-active {
                    >a {
                        color: c(body);
                    }
                }
            }
        }
    }


    .woocommerce-product-details__short-description {
        margin-bottom: 15px;
    }

    .woocommerce-product-attributes-item__label {
        text-align: left;
    }

    @include bp(sm, max) {
        .woocommerce-MyAccount-navigation {
            border-bottom: 1px solid c(grey);
            margin-bottom: 10px;

            ul {
                display     : flex;
                flex-flow   : row wrap;
                margin-left : -5px;
                margin-right: -5px;

                >li {
                    padding: 5px 10px;
                }
            }
        }
    }


}



.store_categories_menu {

    margin-top: 8px;

    >ul {
        list-style: none;


        >li {


            >a {
                font-weight: 600;
                color      : c(body);
            }

            &.is-active {
                >a {
                    color: c(blue);
                }
            }
        }
    }
}

@include bp(sm) {
    .store_categories_menu {

        >ul {
            list-style  : none;
            display     : flex;
            flex-flow   : row;
            margin-left : -8px;
            margin-right: -8px;

            >li {
                position     : relative;
                padding-left : 8px;
                padding-right: 8px;

                // &:after {
                //     position        : absolute;
                //     top             : 50%;
                //     transform       : translateY(-50%);
                //     right           : 0;
                //     width           : 1px;
                //     height          : 14px;
                //     background-color: c(body);
                //     content         : '';
                // }

                border-right: 1px solid c(body);

                &:last-child() {
                    border-right: none;
                }
            }
        }
    }
}



@include bp(sm, max) {

    .store_categories_menu {
        border-top   : 1px solid c(grey);
        padding-top  : 10px;
        margin-bottom: 40px;
    }
}


@include bp(xs, max) {
    .woocommerce-ordering {
        width: 100%;

        select {
            width: 100%;
        }
    }
}


.store-user-menu {
    background-color: c(blue, dark);
    border-bottom   : 1px dashed rgba(255, 255, 255, .25);

    ul {
        list-style     : none;
        display        : flex;
        flex-flow      : row;
        justify-content: space-between;
        align-items    : center;
        padding        : 0 20px;

        >li {
            padding    : 5px 8px;
            line-height: 1.2;

            >a {
                font-family: $body-font;
                font-weight: 400;
                color      : c(white);
                font-size  : 12px;

                &:before {
                    position      : relative;
                    top           : -1px;
                    font-family   : 'Fontello';
                    content       : $icon-user;
                    font-size     : 10px;
                    margin-right  : 5px;
                    margin-bottom : 2px;
                    vertical-align: baseline;
                }

                &:hover,
                &:focus {
                    color: c(blue, light);
                }
            }

            &.user-username {
                >a:before {
                    content: $icon-user;
                }
            }

            &.user-logout {
                >a:before {
                    content: $icon-left-1;
                }
            }
        }

    }
}



@include bp(xs, max) {
    .store-user-menu {

        ul {

            >li {
                max-width    : 200px;
                overflow     : hidden;
                text-overflow: ellipsis;
            }
        }
    }
}

@include bp(xs, max) {
    .store-user-menu {

        ul {
            padding: 0 8px;
        }
    }
}

.page-template-template-home {
    .store-user-menu {
        position: relative;
        z-index : 1;
    }
}

.nav-link {
    .cart-items {
        background-color: c(blue);
        border-radius: 8px;
        color: c(white);
        margin: 0 4px;
        padding: 4px;
    }
}