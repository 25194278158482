.wysiywg table {
    width: 100% !important;
    margin-top: 0;
    margin-bottom: 40px;

    tbody {
        tr {
            &:nth-child(odd) {
                background-color: #f9f9f9;
            }

            &:nth-child(even) {
                background-color: #e9eff2;

                td {
                    background-color: transparent !important;
                }
            }

            >td {
                padding: 10px;

                &:first-child {
                    width: 50%;
                }
            }
        }
    }
}