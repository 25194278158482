.store-cart-widget {

    position        : fixed;
    z-index         : 1000;
    top             : 25%;
    left            : -1px;
    transform       : translateX(-100%);
    background-color: transparent;
    display         : flex;
    flex-flow       : row;
    align-items     : center;
    transition      : all .3s ease-out 1.5s;
    pointer-events  : none;

    &.is-active {
        transform     : translateX(0);
        pointer-events: auto;

        .store-cart-widget-items {
            &:before {
                left: 48%;
            }
        }
    }

    &:hover {
        .store-cart-widget-items+.store-cart-widget-draw {
            transform: translateX(0);
        }
    }



    &-items {
        position                  : relative;
        display                   : flex;
        flex-flow                 : column;
        background-color          : c(blue);
        color                     : #fff;
        padding                   : 10px;
        z-index                   : 10;
        border-top-right-radius   : 5px;
        border-bottom-right-radius: 5px;
        min-width                 : 50px;
        font-family               : $title-font;
        font-weight               : 600;

        >span {
            color: #fff;
        }

        &:before {
            display     : block;
            position    : absolute;
            top         : -20px;
            left        : -24px;
            transform   : translateX(-51%);
            content     : $icon-basket;
            font-family : 'Fontello';
            font-size   : 24px;
            line-height : 20px;
            color       : c(blue);
            font-weight : normal;
            text-indent : 0;
            margin-right: 8px;
            font-weight : normal;
            text-align  : center;
            transition  : .3s all ease-out 2s
        }

    }

    &-draw {
        position                     : absolute;
        z-index                      : 5;
        display                      : flex;
        flex-flow                    : row;
        border-top-right-radius      : 5px;
        border-bottom-right-radius   : 5px;
        padding                      : 4px 4px 4px 10px;
        background                   : linear-gradient(90deg, darken(c(blue), 5%) 0%, c(blue) 100%);
        transition                   : all .3s ease-in-out;
        transform                    : translateX(-100%);
        top                          : 0;
        left                         : 0;
        margin-left                  : 45px;

        >.btn {
            margin          : 0;
            border-right    : 1px solid rgba(c(white), .25);
            font-weight     : 600;
            font-size       : 14px;
            display         : block;
            padding         : 7.5px;
            color           : c(white);  
            &:last-child {
                border: none;
            }
        }
    }


    .cart-items {
        width     : 100%;
        text-align: center;
    }

}

@include bp(sm, max) {
    .store-cart-widget {
        left     : auto;
        right    : -1px;
        transform: translateX(100%);

        &-items {
            padding                  : 10px 5px;
            font-size                : 14px;
            min-width                : 40px;
            border-radius            : 0;
            border-top-left-radius   : 5px;
            border-bottom-left-radius: 5px;

            &:before {
                transform: scaleX(-1);
                left     : auto;
                right    : -50px;
            }

        }

        &-draw {
            display: none !important;
        }

        &.is-active {
            transform     : translateX(0);
            pointer-events: auto;

            .store-cart-widget-items {
                left: auto;

                &:before {
                    right: -1%;
                }
            }
        }
    }
}


@include bp(sm) {
    .store-cart-widget {
        top: 220px;
    }
}

@include bp(md) {
    .store-cart-widget {
        top: 230px;
    }
}

.woocommerce-account,
.woocommerce-checkout,
.woocommerce-cart {
    .store-cart-widget {
        display: none !important;
    }
}