.footer {
	position: relative;
	width: 100%;
	padding:20px;
	background:lighten(c(grey, dark), 10%);
	background: linear-gradient(135deg, rgba(darken(c(blue), 20%),1) 0%,rgba(c(grey,dark),1) 100%);
	&:before {
		position: absolute;
		//z-index: 20;
		top:0;
		left:0;
		width: 100%;
		height: 100%;
		content: '';
		background: transparent url('../images/mx2-masked.png') 50% center no-repeat;
		background-size: cover;
		opacity: .33;
		pointer-events:none;
	}
	ul {	
		width: 100%;			
		> li {
			color: #fff;
			font-weight: 300;
			letter-spacing: .5px;
			p {		
				font-size: 14px;
				margin-top: 0;
				color: #fff;
				font-weight: 300;
				letter-spacing: .5px;
			}
			a {
				color: lighten(c(blue, medium), 5%);
				font-weight: 300;
				&:hover, &:focus {
					color: c(white);
				}
				&[class*="link"] {
					&:before {
						color: c(white);
					}
				}
			}
		}
	}
	&__logo {
		width: 100%;
		text-align: center;
		.site-logo {
			width: 100%;
			background-image: url('../images/mx-aircraft-logo-mono.png');
			background-position-x:center;
		}
	}
	&__contact {
		> li {
			display: block;
			white-space: nowrap;
			> span {
				display: none;
				width: 20px;
			}
		}
	}
}


@include bp(xs) {
	.footer {
		flex:1 1 100;
		padding-top: 35px;
		&__upper {
			float: none;
			clear: both;
			width: 100%;
			display: flex;
			flex-flow:row;
			> ul {
				flex: 1 1 33.33333%;
				max-width: 33.33333%;
			}
		}
		&__lower {
			float: none;
			clear: both;
			width: 100%;
			padding-top: 0;
		}

		ul {
			width: auto;
			display: inline-block;
			&:not(:first-child) {				
				margin-left:  30px;
			}
			+div {
				display: inline-block;
			}
		}

		&__copyr {
			position: relative;
			top:-27px;
			li {				
				font-size: 11.5px;
			}
		}

		&__logo {
			float:left;
			width: auto;
			.site-logo {
				width: 120px;
				height: 50px;
				background-position: -10px -10px;
			}
		}
		
		&__address {
			float:right;
			align-self: self-end;
		}

		&__contact {
			float:right;
			> li {
				display: block;
				padding-bottom: 10px;
				> [class*="link"] {
					&:before {
						//height: 28px;
					}
				}
			}
		}
	}
}

@include bp(md) {
	.footer {
		padding-top: 50px;
		&:before {
			background-image: url('../images/mxs-masked.png');
		}
		&__copyr {
			position: relative;
			top:-27px;
		}
	}
}



// mobile overrides
@include bp(xs, max) {
	.footer {

		ul {
			position: relative;
			margin-top: 20px;
			padding-bottom: 20px;
			&:after {		
				display: block;
				position: absolute;
				z-index: 2;
				content: '';
				left:50%;
				bottom:0;
				transform:translateX(-50%);
				width: 75%;
				height: 1px;
				background-color: #fff;
				background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 25%,rgba(255,255,255,1) 75%,rgba(255,255,255,0) 99%,rgba(255,255,255,0) 100%); 
				opacity: .25;
			}	
			> li {
				text-align: center;
				margin-bottom: 10px;
			}		
		}

		ul.footer__copyr {
			padding-bottom: 0;
			+div > ul {
				padding-top: 0;
				margin-top: 0;	
				&:after {
					display: none;
				}			
			}
			&:after {
				display: none;
			}
		}

		&__contact {
			> li {	
				width: 100%;
				text-align:center;					
				a {
					font-size: 18px;
					margin-bottom: 10px;
					padding-left: 0;
					padding-top: 25px;
					&:before {
						top:0;
						left:50%;
						transform:translate(-50%, 0);
						text-align: center;
						//margin-top: 4px;
						font-size: 20px;
						font-weight: normal;
					}					
				}
			}
		}
	}


}