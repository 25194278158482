
.header,
.header .nav-toggle,
.header .site-logo,
.header .header__banner {
    transform-style: preserve-3d;
    backface-visibility: hidden;
}
.header,
.header .nav-toggle,
.header .site-logo {
	transition:.3s all $cb;
}



.main {	
	transition:.3s margin-top $cb;
}
@include bp(sm, max) {
	.header-narrow {
		.main {
			margin-top: 49px;
		}
		.header {
		    transform:translateY(-10px);
		    .header__banner {
			    .site-logo {
			    	transform:scale(.75);
			    }
			}
			.nav-toggle {
				top:-10px;
			}
		}
	}
}