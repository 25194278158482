/*
	Mixin to add css property with rem units, with a px fallback
	Usage:
	@include rem(margin, 10 20 5 40);
	or
	@include rem(font-size, 38);
 */
@mixin rem($property, $values) {
	$px : ();
	$rem: ();

	@each $value in $values {

		@if $value == 0 or $value == auto {
			$px : append($px , $value);
			$rem: append($rem, $value);
		}

		@else {
			$unit: unit($value);
			$val: parseInt($value);

			$px : append($px, #{$val}px);
			$rem: append($rem, #{$val / $base-font-size}rem);
		}
	}

	#{$property}: $px;
	#{$property}: $rem;
}

/*
	Rem mixin for font-size
 */
@mixin fs($size) {
	@include rem(font-size, $size);
}



/*
	Mixin to add css transition. Can use defaults or pass in custom values
 */
@mixin trans( $time: .3s, $property: all, $easing: $cb){
	transition: $time $property $easing;
}
@mixin trans-p( $time: .3s, $easing: $cb){
	transition: $time transform $easing;
}


/*
	Mixin to add container styles. Pass '0' to prevent the padding being added e.g.
	@include container(0);
 */
@mixin container($padding: #{$container-gutter}px) {
	margin-right: auto;
	margin-left: auto;
	@if $padding != 0 {
		padding-left: $padding;
		padding-right: $padding;
	}
	@include bp(md) {
		width: 750px;
	}
	@include bp(lg) {
		width: 970px;
	}
	@include bp(xl) {
		width: 1170px;
	}
}


/*
	 Button Icon Mixin for psuedo elements after and before
	 @icnlude icon-valign-icon();

*/
@mixin icon-valign-icon () {
    text-indent: 0px;
    content: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    font-family: FontAwesome;
    color: white;
    text-align: center;
    width: 100%;
    transition:.25s color;
}
