.map-canvas {
	width: 100%;
	height: 260px;
}

@include bp(xs) {
	.map-canvas {
		height: 280px;
	}
}

@include bp(md) {
	.map-canvas {
		height: 360px;
	}
}


@include bp(lg) {
	.map-canvas {
		height: 550px;
	}
}


