// Clearfix

// @include clearfix;

@mixin clearfix {
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}
@mixin alpha-attribute($attribute, $color, $background) {
  $percent: alpha($color) * 100%;
  $opaque: opacify($color, 1);
  $solid-color: mix($opaque, $background, $percent);
  #{$attribute}: $solid-color;
  #{$attribute}: $color;
}
@mixin box--grad {
  /* IE9 SVG, needs conditional override of 'filter' to 'none' */
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMC42NSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.95) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(0,0,0,0)), color-stop(100%,rgba(0,0,0,0.95))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.95) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.95) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.95) 100%); /* IE10+ */
  background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.95) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-8 */
}

@mixin rounded_corners ($radius) {
  -moz-border-radius: $radius;
  -webkit-border-radius: $radius;
  -o-border-radius: $radius;
  border-radius: $radius;
}
@mixin background($imgpath,$position:0 0,$repeat: no-repeat, $color: $colorTransparent) {
  background: {
    image: url($imgpath);
    position: $position;
    repeat: $repeat;
    color: $colorTransparent;
  }
}

// arrows
@mixin arrow-down($width, $height, $color) {
  border-style: solid;
  border-width: $height $width 0 $width;
  border-color: $color transparent transparent;
}

@mixin arrow-up($width, $height, $color) {
  border-style: solid;
  border-width: 0 $width $height $width;
  border-color: transparent transparent $color;
}

@mixin arrow-left($width, $height, $color) {
  border-style: solid;
  border-width: $width $height $width 0;
  border-color: transparent $color transparent;
}

@mixin arrow-right($width, $height, $color) {
  border-style: solid;
  border-width: $width 0 $width $height;
  border-color:  transparent transparent transparent $color;
}

@mixin arrow-up-rounded($width, $height, $pc, $color) { //http://dabblet.com/gist/4592062
  position: relative;
  background-color: $color;
  text-align: left;
  width:  $width;
  height: $height;
  border-top-right-radius: $pc;
  transform: rotate(-60deg) skewX(-30deg) scale(1,.866);

  &:before,
  &:after {
    content: '';
    position: absolute;
    background-color: inherit;
    width:  $width;
    height: $height;
    border-top-right-radius: $pc;
  }

  &:before {
    transform: rotate(-135deg) skewX(-45deg) scale(1.414,.707) translate(0,-50%);
  }

  &:after {
    transform: rotate(135deg) skewY(-45deg) scale(.707,1.414) translate(50%);
  }
}

@mixin arrow-down-rounded($width, $height, $pc, $color) { //http://dabblet.com/gist/4592062
  position: relative;
  background-color: $color;
  text-align: left;
  width:  $width;
  height: $height;
  border-top-right-radius: $pc;
  transform: rotate(-120deg) skewX(-30deg) scale(1,.866);

  &:before,
  &:after {
    content: '';
    position: absolute;
    background-color: inherit;
    width:  $width;
    height: $height;
    border-top-right-radius: $pc;
  }

  &:before {
    transform: rotate(-135deg) skewX(-45deg) scale(1.414,.707) translate(0,-50%);
  }

  &:after {
    transform: rotate(135deg) skewY(-45deg) scale(.707,1.414) translate(50%);
  }
}

@mixin arrow-left-rounded($width, $height, $pc, $color) { //http://dabblet.com/gist/4592062
  position: relative;
  background-color: $color;
  text-align: left;
  width:  $width;
  height: $height;
  border-top-right-radius: $pc;
      -webkit-transform: rotate(-30deg) skewX(-30deg) scale(1,.866);
      -ms-transform: rotate(-30deg) skewX(-30deg) scale(1,.866);
  transform: rotate(-30deg) skewX(-30deg) scale(1,.866);

  &:before,
  &:after {
    content: '';
    position: absolute;
    background-color: inherit;
    width:  $width;
    height: $height;
    border-top-right-radius: $pc;
  }

  &:before {
      -webkit-transform: rotate(-135deg) skewX(-45deg) scale(1.414,.707) translate(0,-50%);
      -ms-transform: rotate(-135deg) skewX(-45deg) scale(1.414,.707) translate(0,-50%);
    transform: rotate(-135deg) skewX(-45deg) scale(1.414,.707) translate(0,-50%);
  }

  &:after {
      -webkit-transform: rotate(135deg) skewY(-45deg) scale(.707,1.414) translate(50%);
      -ms-transform: rotate(135deg) skewY(-45deg) scale(.707,1.414) translate(50%);
    transform: rotate(135deg) skewY(-45deg) scale(.707,1.414) translate(50%);
  }
}

@mixin arrow-right-rounded($width, $height, $pc, $color) { //http://dabblet.com/gist/4592062
  position: relative;
  background-color: $color;
  text-align: left;
  width:  $width;
  height: $height;
  border-top-right-radius: $pc;
  transform: rotate(-90deg) skewX(-30deg) scale(1,.866);

  &:before,
  &:after {
    content: '';
    position: absolute;
    background-color: inherit;
    width:  $width;
    height: $height;
    border-top-right-radius: $pc;
  }

  &:before {
    transform: rotate(-135deg) skewX(-45deg) scale(1.414,.707) translate(0,-50%);
  }

  &:after {
    transform: rotate(135deg) skewY(-45deg) scale(.707,1.414) translate(50%);
  }
}

// Clearfix
@mixin clearfix {
  &:before {
    content: "";
    display: table;
    clear: both;
  }
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

// Vertically center
@mixin vertical-align {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

//Cross browser opacity: e.g 0.5
@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}
@mixin transition($args) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

//Returns font size in rems and px
@mixin font-size($sizeValue: 18) {
  font-size: $sizeValue + px;
  font-size: ($sizeValue/10) + rem;
}

//Returns font size in rems and px
@mixin line-height($sizeValue: 22) {
  line-height: $sizeValue + px;
  line-height: ($sizeValue/10) + rem;
}

//maintain aspect ratio
@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

/* mixin for multiline */
@mixin multiLineEllipsis($lineHeight: 12rem, $lineCount: 1, $bgColor: white){
  overflow: hidden;
  position: relative;
  line-height: $lineHeight;
  max-height: $lineHeight * $lineCount; 
  text-align: justify;
  margin-right: -1em;
  padding-right: 1em;
  &:before {
    content: '...';
    position: absolute;
    right: 0;
    bottom: 0;
  }
  &:after {
    content: '';
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: $bgColor;
  }
}