.accordion {
	list-style-type: none;
	margin-top: 20px;
	&:last-of-type {
		margin-bottom: 30px;
	}
	.accordion-item {
		margin-top:20px;
		.accordion-title {
			position: relative;
			display: block;
			color: c(grey, dark);
			padding: 8px 50px 8px 0px;
			border-bottom: 1px solid c(grey, dark);
			text-transform: uppercase;			
	        &:after {
	            position: absolute;
	            top: 50%;
	            right: 0;
	            width: 20px;
	            text-align: center;
	            line-height: 100%;
	            content: $icon-angle-down;
	            display: block;
	            font-family: 'Fontello';
	            font-size: 18px;
	            font-weight: normal;
	            color: c(grey,dark);
	            transition: .3s all $cb;
	            transform: translateY(-50%) rotate(0deg);
	            transform-origin: center center;
			    transform-style: preserve-3d;
			    backface-visibility: hidden;
			}
			h3 {
				display: block;	
				font-size: 14px;
				letter-spacing: 1px;
				font-weight: 600;
				margin: 0;						
			}
			&:hover, &:focus {
				//background-color: darken(c(grey,light), 10%);
				color: c(blue);
				border-color: c(blue);
				&:after {
					color: c(blue);
				}
			}
		}
		.accordion-content {
			display: none;
			padding-top: 20px;
			overflow: hidden;			
		}
		&.is-active {
			.accordion-title {
				//background-color: darken(c(blue), 10%);
				&:after {
					transform: translateY(-50%) rotate(180deg);
				}
			}
		}
	}
}