.page-breadcrumbs {
    display: none;

    >li {
        display: inline-block;
        font-size: 13px;
        font-weight: 400;
        text-transform: uppercase;
        padding: 15px 0 8px;

        &.separator {
            width: 20px;
            text-align: center;

            &:after {
                display: inline-block;
                //font-family: Fontello;
                content: '/'; //$icon-right-1;
                font-size: 10px;
                color: c(blue);
            }
        }
    }
}


@include bp(sm) {
    .page-breadcrumbs {
        display: block;

        >li {
            font-size: 12px;
        }

        +h1 {
            margin-top: 0;
        }
    }
}

@include bp(md) {
    .page-breadcrumbs {
        >li {
            padding: 24px 0 12px
        }
    }
}