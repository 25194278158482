.gallery {
	display: flex;
	flex-flow:row wrap;
	width: 100%;
	float:none;
	clear: both;
	margin:30px auto;

	.gallery-item {
		flex:1 1 50%;
		max-width: 50%;
		float:left;
		border:.1px solid rgba(#fff, .2);
		> a {
			display: block;
			font-size: 0;
		}
		img {
			font-size: 0;
		}
	}
}

@include bp(xxs) {
	.gallery {
		.gallery-item {
			flex:33.33333%;
			max-width:33.33333%;
		}
	}
}


@include bp(xs) {
	.gallery {
		.gallery-item {
			flex:25%;
			max-width:25%;
		}
	}
}


@include bp(sm) {
	.gallery {
		///flex-flow:row;
		.gallery-item {
			flex:(100% / 5);
			max-width:(100% / 5);
		}
	}
}


