.wysiwyg {
	ul,
	ol {
		float:none;
		clear: both;
		list-style: none;
		list-style-type: none;
		margin: 10px 0;
		> li {
            position: relative;
            margin-left: 30px;
            padding-bottom:5px;
            min-height: 16px;
		}
	}

    ol > li {
        counter-increment: step-counter;

        &:before {
            content: counter(step-counter);
            position: absolute;
            top:4px;
            left:-25px;
            font-size: 12px;
            font-size: 11px;
        }
    }

    ul {
		//list-style-type: disc;
        > li {

            &:before {
                content: '';
                display: block;
                position: absolute;
                top:8px;
                left:-22px;
                background-color: c(grey, medium);
                width: 5px;
                height: 5px;
                border-radius: 50%;
            }

        }
    }

	strong {
		margin:10px 0;
		letter-spacing: .2px;
		//font-size: 115%;
		font-weight: 700;
		+ p {
			margin-top: 10px;
		}
	}

	p strong,
	a strong {
		font-size: 100%;
	}

	small {
		font-size: 75%;
	}

	sub,
	sup {
		position: relative;
		font-size: 66%;
		margin:0 3px;
	}
	sub {
		top:5px;
	}
	sup {
		top:-5px;
	}

	.aligncenter {
		text-align: center;
	}
	.alignright {
		text-align: right;
	}


	img.alignleft {
		display: block;
		margin-right: auto;
	}
	img.aligncenter {
		display: block;
		margin-left: auto;
		margin-right: auto;	
	}
	img.alignright {
		display: block;
		margin-left: auto;
	}



}