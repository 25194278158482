.contact-form {
	position: relative;
	&__overlay,
	&__response,
	&__progress {
		position: absolute;
		display: none;		
	}
	#fname-group,
	#fname {
		visibility: hidden;
		height: 0;
		overflow: hidden;		
	}

	.contact-form__response {
		z-index: 30;
		left:-10px;
		top:auto;
		bottom: 55px;
		transform:none;
		background-color: darken(c(grey, light),5%);
		padding: 10px;

		h3 {
			margin-top: 0;
			margin-bottom: 0;
		}
		p {
			margin-top: 5px;
		}
		&:after {
			display: block;
			content: '';
			position: absolute;
			top:100%;
			left:35px;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 10px 5px 0 5px;
			border-color: darken(c(grey, light),5%) transparent transparent transparent;
		}
	}

	&.disabled {
		.contact-form__overlay {
			display: block;
			z-index: 10;
			top:0;
			left:0;
			width: 100%;
			height: 100%;
			background-color: #fff;
			opacity: .75;
		}
	}



	&.sending {
		.contact-form__progress {
			z-index: 20;
			display: block;
			bottom: 18px;
			left:80px;
			.spinner {
				display: block;
			}			
		}
	}

	&.error,
	&.success {
		.contact-form__response {
			display: block;
		}
	}
}




