/*
	IMPORTANT
	There are only very few basic styles here.
	Please avoid making changes to this file as it may cause problems within other components.
*/


* {
    box-sizing: border-box;
    font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -moz-font-smoothing: grayscale;
    font-smooth: always;
    text-rendering: optimizeLegibility;
}

// Very base styles
html,
body {
    width: 100%;
    overflow-x: hidden;
}

body {
    font-family: $body-font;
    font-style: normal;
    color: c(grey, dark);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $body-font;
    line-height: 1.4;
}

h1,
h2,
strong {
    font-weight: 600;
}

h1,
h2,
h3,
    {
    letter-spacing: .5px;
}

h3,
h4,
h5,
h6 {
    font-weight: 800;
    letter-spacing: 0;
}

h1>em {
    font-weight: 700;
    font-style: italic;
}

h1 {
    font-family: $display-font;
    font-size: 24px;

}

h2 {
    font-family: $display-font;
    font-size: 20px;
}

h3 {
    font-family: $display-font;
    margin: 15px 0;
    font-size: 16px;
}


h1.entry-title {
    margin-top: 30px;
}

@include bp(sm) {
    h1.entry-title {
        margin-top: 0;
    }
}

a {
    color: c(blue, access);
    transition: .25s color, .25s border, .25s background-color;
    text-decoration: none;
    outline: none;

    &:hover,
    &:focus {
        color: c(black);
        text-decoration: none;
    }
}

p {
    margin-top: 15px;
    font-weight: 400;

    &.lead {
        font-size: 16px;
    }
}

p,
ul>li,
ol>li {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: .25px;
    line-height: 1.5;
}

strong {
    font-weight: 600;
    line-height: 1.3;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.highlighted,
.highlighted-box {
    display: block;
    float: none;
    clear: both;
    padding: 30px 40px;
    margin: 20px -40px;

    font-family: $display-font;
    font-size: 16px;
}

.highlighted {
    position: relative;

    &:after,
    &:before {
        position: absolute;
        z-index: 0;
        display: block;
        content: '';
        left: 0;
        width: 100%;
        height: 6px;
        background-color: c(grey, light);
        background: linear-gradient(135deg, rgba(darken(c(blue), 30%), .15) 0%, rgba(c(grey, dark), .2) 100%);
    }

    &:before {
        top: 0;
    }

    &:after {
        bottom: 0;
    }
}

.highlighted-box {
    background-color: c(grey, light);
    background: linear-gradient(135deg, rgba(darken(c(blue), 30%), .15) 0%, rgba(c(grey, dark), .2) 100%);
}

@include bp(xxxs) {

    .highlighted,
    .highlighted-box {
        padding: 30px 80px;
        margin: 20px -40px;
    }
}

@include bp(xs) {

    .highlighted,
    .highlighted-box {
        padding: 30px 80px;
        margin: 20px -60px;
    }
}


@include bp(sm) {
    h1 {
        font-size: 30px;
    }

    h2 {
        font-size: 24px;
    }

    .highlighted,
    .highlighted-box {
        padding: 30px 60px;
        margin: 20px 0px;
    }

}

@include bp(md) {
    h1 {
        font-size: 34px;
    }

    h2 {
        font-size: 28px;
    }

    p {
        font-size: 15px;

        &.lead {
            font-size: 18px;
        }
    }

}

img {
    max-width: 100%;
    height: auto;
}


hr {
    display: block;
    float: none;
    clear: both;
    margin-top: 30px;
    margin-bottom: 3 0px;
    height: 1px;
    background: darken(c(grey, light), 10%);
    text-align: left;
    border: none;
    appearance: none;
}



// BASE FORM STYLES
label.error {
    display: none !important;
}

label,
textarea,
input[type="text"],
input[type="password"],
input[type="tel"],
input[type="date"],
input[type="date-local"],
input[type="email"] {
    width: 100%;
    //max-width: 600px;
    font-family: $body-font;
}

textarea,
input[type="text"],
input[type="password"],
input[type="tel"],
input[type="date"],
input[type="date-local"],
input[type="email"] {
    appearance: none;
    height: 35px;
    border: 1px solid darken(c(grey, light), 10%);
    line-height: 1.5;
    padding: 5px;
    transition: .3s border, .3s background-color, .3s color;
    font-size: 16px;
    border-radius: 0;

    &:focus {
        border-color: c(blue);
        outline: none;
    }
}

textarea {
    height: 200px;
    padding-top: 15px;
    padding-bottom: 15px;
}


button {
    margin: 15px 0;
    appearance: none;
    padding: 6px 12px;
    border: none;
    border-radius: 2px;
    background-color: c(blue);
    font-family: $display-font;
    letter-spacing: .5px;
    color: #fff;
    transition: .3s border, .3s background-color, .3s color;

    &:hover,
    &:focus {
        cursor: pointer;
        background-color: c(grey, dark);
        outline: none;
    }
}

label {
    display: block;
    font-size: 13px;
    padding: 12px 0 4px;
    transition: .3s border, .3s background-color, .3s color;
}

.has-error {

    textarea,
    input[type="text"] {
        border-color: red !important;
    }

    label.error {
        display: block !important;
    }
}

@-webkit-keyframes autofill {
    to {
        color: #666;
        background: transparent;
    }
}

input:-webkit-autofill {
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
}


select {
    padding: 3px;
    font-family: $body-font;
    border: 1px solid c(grey);

    &:hover,
    &:focus {
        border-color: c(blue);
    }
}

.desktop-only {
    display: none;
}


@include bp(sm) {
    .mobile-only {
        display: none !important;
    }

    .desktop-only {
        display: block;
    }
}