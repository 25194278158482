 /**********************************************************************************************

	About this SASS framework

	Auther
	Steven Green <steve.green@thecreativegreen.com>

	Contributers

	Please refer to _site-settings.scss for all site wide breakpoints, gutters, colours etc..

	Please follow the BEM methology - Block, element, modifier where possible.
	http://getbem.com/introduction/
	http://getbem.com/naming/

************************************************************************************************/



 // Mixins
 @import "tools/all";

 // Custom Mixins
 @import "mixins/mixins";

 // Custom Site Settings
 @import "site-settings";

 // import foundation sass
 @import "foundation-sites/scss/foundation.scss";

 //  @import "foundation-settings"; // customised foundation settings within local library
 //  @import "components/tabs";
 //@include foundation-tabs;
 //@include foundation-sticky;
 @include foundation-flex-grid;

 @import "vendor/photoswipe";

 // Vendor
 @import "fontello/scss/fontello";
 @import "font-awesome/scss/font-awesome";
 @import "owl.carousel/src/scss/owl.carousel";

 // Fonts
 @import "fonts/fonts";

 // Layouts
 @import "partials/normalise";
 @import "partials/base";
 @import "partials/layouts/layout";

 // Global
 @import "partials/global/header";
 @import "partials/global/header-narrow";
 @import "partials/global/nav";
 @import "partials/global/nav-toggle";
 @import "partials/global/footer";

 // Typography
 @import "partials/typography/typography";
 @import "partials/typography/wysiwyg";

 // UI
 @import "partials/ui/buttons";
 @import "partials/ui/spinner";

 // Components
 @import "partials/components/page-breadcrumbs";
 @import "partials/components/accordions";
 @import "partials/components/carousel";
 @import "partials/components/cards";
 @import "partials/components/gallery";
 @import "partials/components/testimonials";
 @import "partials/components/tables";
 @import "partials/components/map-canvas";
 @import "partials/components/contact-us";
 @import "partials/components/contact-form";
 @import "partials/components/store";
 @import "partials/components/store-cart-widget";






 // Typography - Article / WYSIWYG Styles
 //@import "partials/typographic/all";


 // User interface
 //@import "partials/ui/all";


 // Global
 //@import "partials/global/all";



 // IE fixes
 //@import "partials/ie";