.site-logo {
	display: block;
	position: relative;
	text-indent: -9999px;
	width: 85px;
	height: 60px;
	background: transparent url('../images/mx-aircraft-logo.png') 0 0 no-repeat;
	background-size:contain;
}

.header {
	position: relative;
	z-index: 15;
	background-color: lighten(c(grey,dark), 10%);
	&:before {
		position: absolute;
		content: '';
		left:0;
		top:0;
		width: 100%;
		height: 100%;
		background: #282828 url('../images/header-background-small@2x.jpg') center 0 no-repeat;
		background-size: cover;
		opacity: 1;
	}

	.header__banner {
		position: relative;
		z-index: 120;
		width: 100%;
		height: 59px;
		//background: c(grey,dark);
		padding-top: 10px;
		.site-logo {
			top:-5px;
			width: 100%;
			background-position: center;
		}
		.site-logo__link {
			display: block;
			position: absolute;
			top:8px;
			left:50%;
			transform:translateX(-50%);
			width: 106px;
			height: 60px;
			background-color: transparent;
			text-indent: -99999px;
		}
	}
	.nav-toggle {
		position: absolute;
		top:-14px;
		right:0;
	}



}

@include bp(sm) {
	.header {
		background-color: transparent;
		&:before {
			background-size: auto 79px;
			height: 79px;
		}
		.nav-toggle {
			display: none;
		}
		.header__banner {	
			height: 79px;
			//min-height: 120px;
			padding-top: 0;
			text-align: center;			
				
			.site-logo {					
				width: 160px;
				height: 140px;
			    position: relative;
			    top: -25px;
			    display: inline-block;
			    margin-left: auto;
			    margin-right: auto;
			    pointer-events:none;
			}
			.site-logo__link {
				width: 140px;
				height: 70px;
			}
			
		}
		.primary_nav {
			position: relative;
			z-index: 99;
			background: transparent;
			&:before {
				display: block;
				position: absolute;
				z-index: 2;
				content: '';
				left:50%;
				top:0;
				transform:translateX(-50%);
				width: 720px;
				height: 1px;
				background-color: #fff;
				background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 25%,rgba(255,255,255,1) 75%,rgba(255,255,255,0) 99%,rgba(255,255,255,0) 100%); 
				opacity: .25;
			}
		}
	}
}

@include bp(md) {
	.header {
		background-color: transparent;
	}
}



@include bp(sm, max) {
	.header {
		position: fixed;		
	    //z-index: 110;
	    top: 0;
	    left: 0;
	    width: 100%
	}
}

// http://localhost:3000/wp-content/themes/theme-main/assets/dist/css/assets/dist/images/mx-aircraft-logo.png