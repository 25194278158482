/* Main layout */

html,
body {
    -ms-overflow-style: scrollbar;
    overflow-style: scrollbar;
    -webkit-overflow-scrolling: touch;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

/* Sticky Footer with Flexbox */
.wrapper {
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    min-height: 100vh;
    flex-direction: column;

    .main {
        flex: 1;
        margin-top: 59px;
        margin-bottom: 30px;
    }
}

.home .wrapper .main {
    margin-bottom: 0;
}

@include bp(sm) {
    .wrapper {
        .main {
            margin-top: 0;
        }
    }
}

@include bp(md) {
    .wrapper {
        .main {
            margin-top: 0;
        }
    }
}

// Fixes for ie 
@media screen and (min-width:0\0) {
    .wrapper {
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        display: block;
        min-height: auto;

        .main {
            flex: none;
        }
    }
}


.content {
    &:last-of-type {
        margin-bottom: 50px;
    }
}


.c-row {
    position: relative;
    float: none;
    clear: both;
    margin: 0 $site-gutter;
    max-width: $site-max-width;

    @include bp(xxs) {
        margin: 0 $site-gutter-xxs;
    }

    @include bp(xs) {
        margin: 0 $site-gutter-xs;
    }

    @include bp(sm) {
        margin: 0 $site-gutter-sm;
    }

    @include bp(md) {
        margin: 0 $site-gutter-md;
    }

    @include bp(lg) {
        margin: 0 $site-gutter-lg;
    }

    // Center content past max-width adding both gutters into the equation 
    @include bp($site-max-width-bp) {
        margin: 0 auto;
    }
}

.row {
    margin-left: -0.625rem;
    margin-right: -0.625rem;

    @include bp(xs) {
        margin-left: -0.9375rem;
        margin-right: -0.9375rem;
    }
}

.theme--grey {
    background-color: c(grey, light);
    background: linear-gradient(135deg, rgba(darken(c(blue), 30%), .15) 0%, rgba(c(grey, dark), .2) 100%);
}

.theme--intro {
    position: relative;
    overflow: hidden;

    h1 {
        font-size: 18px;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    p {
        font-size: 16.5px;
        font-weight: 400;

        &:last-of-type {
            padding-bottom: 50px;
        }
    }

    @include bp(sm) {
        padding-bottom: 15px;

        h1 {
            font-size: 22px;
        }
    }

    @include bp(md) {
        padding-bottom: 15px;

        h1 {
            margin-top: 0px;
            font-size: 28px;
        }
    }

    +.c-row {
        margin-top: 30px;
    }
}

.content {
    max-width: $content-max-width;
    margin: 0 auto;
}




@include bp(sm, max) {
    .admin-bar {
        .header {
            position: relative;
            margin-top: 0
        }

        .main {
            margin-top: 0;
        }
    }
}

#wpadminbar {
    position: absolute !important;
}